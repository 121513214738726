import React, { useState } from 'react';
import Layout from '../components/Layout';
import './Form.css';
import '../styles.css';
//import { createSubscription } from '../services/api';

const Subscription = () => {

  /*
  const [email, setEmail] = useState('');
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [message, setMessage] = useState('');

  const handleSubscription = async (e) => {
    e.preventDefault();
    try {
      const response = await createSubscription(email, paymentMethodId);
      setMessage('Subscription successful!');
    } catch (error) {
      setMessage('Subscription failed: ' + error.response.data.error);
    }
  };

  return (
    <Layout>
      <section>
        <div className="container">
          <h2>Subscribe</h2>
          <form onSubmit={handleSubscription} className="form">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Payment Method ID"
              value={paymentMethodId}
              onChange={(e) => setPaymentMethodId(e.target.value)}
            />
            <button type="submit" className="cta-button">Subscribe</button>
          </form>
          <p>{message}</p>
        </div>
      </section>
    </Layout>
  );
  */

  return "";
}

export default Subscription;