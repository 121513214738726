import React from 'react';
import { Link } from 'react-router-dom';
import './Layout.css';

const Layout = ({ children }) => {
  return (
    <div>
      <header>
        <div className="container">
          <div className="logo">pivotbrowser</div>
          <nav>
            <Link to="/">Home</Link>
            <Link to="/register">Register</Link>
            <Link to="/login">Login</Link>
            <Link to="/dashboard">Dashboard</Link>
            <Link to="/download">Download</Link>
            <Link to="/subscribe" className="cta-button">Subscribe</Link>
          </nav>
        </div>
      </header>
      <main>{children}</main>
      <footer>
        <div className="container">
          <p>&copy; 2024 PivotBrowser. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default Layout;