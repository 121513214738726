import React, { useState } from 'react';
import Layout from '../components/Layout';
import './Form.css';
import '../styles.css';
//import { registerUser } from '../services/api';

const Register = () => {

  /** 
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');


  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await registerUser(username, email, password);
      setMessage('Registration successful!');
    } catch (error) {
      setMessage('Registration failed: ' + error.response.data.error);
    }
  };

  return (
    <Layout>
      <section>
        <div className="container">
          <h2>Register</h2>
          <form onSubmit={handleRegister} className="form">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit" className="cta-button">Register</button>
          </form>
          <p>{message}</p>
        </div>
      </section>
    </Layout>
  );

  */

  return ""
}

export default Register;