import React, { useEffect, useRef, useState } from 'react';
import { Layout, Menu, Button, Row, Col, Typography, Card } from 'antd';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import './Home.css';
import JoinWaitlistModal from '../components/JoinWaitlistModal';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;

const Home = () => {
    const sectionsRef = useRef([]);
    const [waitlistModalOpen, setWaitlistModal] = useState(false);
    const [currentSection, setCurrentSection] = useState('hero');


    useEffect(() => {
        const sections = sectionsRef.current;

        console.log("All sections:", sections);

        sections.forEach((section) => {
            
            gsap.fromTo(section, { opacity: 0, y: 50 }, {
                opacity: 1,
                y: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: section,
                    start: 'top center',
                },
            });
        });

        const handleScroll = () => {
            let current = '';
            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                if (window.pageYOffset >= sectionTop - 60) {
                    current = section.getAttribute('id');
                }
            });
            setCurrentSection(current);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const addToRefs = (el) => {
        if (el && !sectionsRef.current.includes(el)) {
            sectionsRef.current.push(el);
        }
    };

    const showModal = () => {
        setWaitlistModal(true);
    };

    const handleClose = () => {
        setWaitlistModal(false);
    };

    const scrollToSection = (id) => {
        console.log(`Scrolling to section: ${id}`); // Debugging line
        gsap.to(window, {
            scrollTo: { y: `#${id}`, offsetY: 60 },
            duration: 1,
        });
    };

    return (
        <Layout className="home-page">
            <Header className="header">
                <div className="container">
                    <div className="logo">pivotbrowser</div>
                    <Menu theme="dark" mode="horizontal" selectedKeys={[currentSection]} className="menu">
                        <Menu.Item key="hero" onClick={() => scrollToSection('hero')}>Home</Menu.Item>
                        <Menu.Item key="features" onClick={() => scrollToSection('features')}>Features</Menu.Item>
                        <Menu.Item key="how-it-works" onClick={() => scrollToSection('how-it-works')}>How It Works</Menu.Item>
                        <Menu.Item key="benefits" onClick={() => scrollToSection('benefits')}>Benefits</Menu.Item>
                        <Menu.Item key="use-cases" onClick={() => scrollToSection('use-cases')}>Use Cases</Menu.Item>
                        <Menu.Item key="subscribe" onClick={() => showModal()}>Join Waitlist</Menu.Item>
                    </Menu>
                </div>
            </Header>
            <Content>
                <section id="hero" ref={addToRefs} className="hero-section">
                    <Row justify="center" align="middle" className="container">
                        <Col span={24}>
                            <Title style={{ color: '#fff' }}>Experience Next-Level Multi-Session Browsing</Title>
                            <Paragraph style={{ color: '#fff' }}>Fast, efficient, and secure. PivotBrowser revolutionizes the way you browse with multi-session management.</Paragraph>
                            <Button type="default" size="large" onClick={showModal} className="waitlist-button">Join Waitlist</Button>
                            <Button type="primary" size="large" onClick={() => scrollToSection('features')} className="subscribe-button">Learn More</Button>
                        </Col>
                    </Row>
                </section>

                <section id="features" ref={addToRefs} className="features-section">
                    <div className="container">
                        <Title level={2}>Features</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8}>
                                <Card title="Tab Management">
                                    <Paragraph>PivotBrowser's tab management allows you to create and manage multiple tabs with isolated sessions and proxies, ensuring each tab operates independently.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <Card title="Proxy Management">
                                    <Paragraph>Manage proxies from multiple providers seamlessly. Quickly switch between proxies to maintain your privacy and access geo-restricted content.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <Card title="Profile Management">
                                    <Paragraph>Handle multiple profiles for different sessions, automating logins and maintaining separate browsing contexts effortlessly.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section id="how-it-works" ref={addToRefs} className="how-it-works-section">
                    <div className="container">
                        <Title level={2}>How It Works</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8}>
                                <Card title="Step 1">
                                    <Paragraph>Download and install PivotBrowser. Follow the easy installation steps to get started quickly.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <Card title="Step 2">
                                    <Paragraph>Configure your proxies and profiles. Load your proxy lists and create profiles for different browsing needs.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <Card title="Step 3">
                                    <Paragraph>Start browsing with isolated sessions. Open multiple tabs, each with its own session, proxy, and profile.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section id="benefits" ref={addToRefs} className="benefits-section">
                    <div className="container">
                        <Title level={2}>Benefits</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8}>
                                <Card title="Privacy and Security">
                                    <Paragraph>Keep your online activities private and secure by using different proxies and sessions for each tab. Prevent tracking and data leaks.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <Card title="Efficiency and Speed">
                                    <Paragraph>Experience fast browsing without sacrificing performance. Our efficient session management ensures minimal resource usage.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <Card title="Enhanced Productivity">
                                    <Paragraph>Manage multiple accounts and tasks simultaneously without logging in and out repeatedly. Streamline your workflow and boost productivity.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section id="use-cases" ref={addToRefs} className="use-cases-section">
                    <div className="container">
                        <Title level={2}>Use Cases</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8}>
                                <Card title="Ticket Purchasing">
                                    <Paragraph>Use PivotBrowser to manage multiple ticket purchasing sessions simultaneously. Ensure you never miss out on limited-time offers and events.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <Card title="Social Media Management">
                                    <Paragraph>Manage multiple social media accounts with ease. Post, comment, and engage across different platforms without switching profiles.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <Card title="Market Research">
                                    <Paragraph>Conduct market research using different sessions to access geo-restricted data and maintain anonymity.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </section>

            </Content>
            <Footer>
                <div className="container">
                    <Paragraph>&copy; 2024 PivotBrowser. All rights reserved.</Paragraph>
                </div>
            </Footer>

            <JoinWaitlistModal visible={waitlistModalOpen} onClose={handleClose} />
        </Layout>
    );
};

export default Home;
