// src/components/JoinWaitlistModal.js
import React, { useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { addToWaitlist } from '../api/supabase/waitlistService';

const JoinWaitlistModal = ({ visible, onClose }) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            await addToWaitlist(values.email, values.name);
            message.success('Successfully added to the waitlist');
            onClose();
        } catch (error) {
            console.log(error);
            message.error('Failed to join the waitlist');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal visible={visible} title="Join the Waitlist" onCancel={onClose} footer={null}>
            <Form onFinish={handleSubmit}>
            <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Please enter your name' }]}
                >
                    <Input placeholder="Name" />
                </Form.Item>
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please enter your email' }]}
                >
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default JoinWaitlistModal;
