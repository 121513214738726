// src/api/supabase/waitlistService.js
import supabase from './supabaseClient';

export const addToWaitlist = async (email, name) => {
    const { data, error } = await supabase
        .from('waitlist')
        .insert([{ name, email}]);

    if (error) {
        throw error;
    }

    return data;
};
