import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Form.css';
import '../styles.css';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    // Fetch user data and subscription status
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/auth/me', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(response.data);
        // Fetch subscription status
        const subResponse = await axios.get('/api/stripe/subscription', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSubscription(subResponse.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, []);

  return (
    <div>
      <h1>Dashboard</h1>
      {user && (
        <div>
          <p>Welcome, {user.username}!</p>
          <p>Email: {user.email}</p>
          <p>Subscription Status: {subscription ? subscription.status : 'Inactive'}</p>
          <button onClick={() => window.location.href = '/download'}>Download Product</button>
        </div>
      )}
    </div>
  );
};

export default Dashboard;