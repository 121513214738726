import React from 'react';
import Layout from '../components/Layout';
import './Form.css';
import '../styles.css';

const Download = () => {
  return (
    <Layout>
      <section>
        <div className="container">
          <h2>Download</h2>
          <p>Download your product here.</p>
        </div>
      </section>
    </Layout>
  );
}

export default Download;