import React, { useState } from 'react';
import Layout from '../components/Layout';
import './Form.css';
import '../styles.css';
//import { loginUser }from '../services/api'

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  /** 
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser(email, password);
      localStorage.setItem('token', response.data.token);
      setMessage('Login successful!');
    } catch (error) {
      setMessage('Login failed: ' + error.response.data.error);
    }
  };


  return (
    <Layout>
      <section>
        <div className="container">
          <h2>Login</h2>
          <form onSubmit={handleLogin} className="form">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit" className="cta-button">Login</button>
          </form>
          <p>{message}</p>
        </div>
      </section>
    </Layout>
  );

  */

  return ""
}

export default Login;